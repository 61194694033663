import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Input,
  Breadcrumb,
  Row,
  Col,
  Form,
  Space,
  message,
} from "antd";
import axios from "axios";
import { API_ROUTES, APP_PARAMS } from "../../utils/constants";
import { getTokenFromLocalStorage } from "../../lib/common";

const Notification = () => {
  const [form] = Form.useForm();

  /**
   * /admin/notification post method
   *
   * {
   * "title": "başlık test",
   * "message": "mesaj test"
   * }
   */

  const onFinishNotification = async (values) => {
    let data = {
      title: values.title,
      message: values.message,
    };

    try {
      const response = await axios({
        method: "post",
        url: API_ROUTES.NOTIFICATION,
        headers: {
          authkey: APP_PARAMS.ADMIN_KEY,
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
          "Content-Type": "application/json",
        },
        data: data,
      });
      message.success("Bildirim Gönderildi!");
    } catch (err) {
      if (err.response) message.error(err.response.data.message);
      else message.error(err.message);
    } finally {
    }
  };

  return (
    <>
      <div
        style={{ margin: "-5px 0px" }}
        className="flex text-lg font-bold rounded-t-3xl backdrop-blur-xl bg-white/60 justify-between p-6  flex-col lg:flex-row"
      >
        <div className="py-4 lg:py-0">
          <Breadcrumb
            items={[
              { title: "Home" },
              { title: <Link to="/admin/category">Category</Link> },
              { title: "Bildirim Gönder" },
            ]}
          />
        </div>
      </div>
      <Row>
        <Col span={10} offset={4}>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinishNotification}
            autoComplete="off"
          >
            <Form.Item
              name="title"
              label="Bildirim Başlığı"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="Bildirim başlığı giriniz" />
            </Form.Item>
            <Form.Item
              name="message"
              label="Bildirim Metni"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="Bildirim metni giriniz" />
            </Form.Item>
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit">
                  Gönder
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default Notification;
