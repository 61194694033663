const API_URL =
  process.env.NODE_ENV === "production"
    ? "https://api.paylasim.app/v1"
    : "https://api.paylasim.app/v1";
const API_URL_IMAGE =
  process.env.NODE_ENV === "production"
    ? "https://api.paylasim.app/static/"
    : "https://api.paylasim.app/static/";
export const API_ROUTES = {
  BASE: `${API_URL}`,

  SIGN_IN: `${API_URL}/auth/login_admin`,
  GET_USER: `${API_URL}/auth/profile`,
  GET_CATEGORY: `${API_URL}/admin/category`,
  GET_SUB_CATEGORY: `${API_URL}/admin/subcategory`,
  GET_TEXT: `${API_URL}/admin/text`,
  GET_MEDIA: `${API_URL}/admin/file`,
  API_IMAGE: `${API_URL_IMAGE}`,
  NOTIFICATION: `${API_URL}/admin/notification`,
};

export const APP_PARAMS = {
  MOBILE_KEY: "dGVzdG1vYmlsZWtleQ==",
  ADMIN_KEY: "dGVzdGF1dGhrZXk=",
};

export const APP_ROUTES = {
  SIGN_UP: "/signup",
  SIGN_IN: "/signin",
  BUSINESS: "/admin/business",
  CATEGORY: "/admin/category",
  SUB_CATEGORY: "/admin/subcategory",
  ADD_TEXT: "/admin/add-text",
  ADD_MEDIA: "/admin/add-media",
  USERS: "/admin/users",
  BUSINESS_TYPE: "/admin/business-type",
  OPERATION_TYPE: "/admin/operation-type",
  AIRCRAFT_TYPE: "/admin/aircraft-type",
  AIRCRAFTS: "/admin/aircrafts",
  MANUFACTURER: "/admin/aircraft/manufacturer",
  EMPLOYEE: "/admin/employee",
  CONFIRM: "/confirm",
  STAFF: "/admin/staff",
  SHOWPAGE: "/showpage",
  SHOWAIRCRAFTS: "/showaircrafts",
  NOTIFICATION: "/admin/notification",
};

export const COMMON_MESSAGE = {
  REGISTER_SUCCESS: `Kullanıcı başarılı bir şekilde oluşturuldu. Mail adresinizden doğrulama yapmanız gerekmektedir.`,
};
